import cx from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { match } from "ts-pattern";

import { ButtonLoader } from "ui/Button/ButtonLoader";
import { DisabledButton } from "ui/Button/DisabledButton";
import { PushableButton } from "ui/Button/PushableButton";

const getAlternativeColors = (alternative: Alternative, isLoading: boolean) =>
  match({ alternative, isLoading })
    .with(
      { alternative: "primary", isLoading: false },
      () =>
        "bg-yellow has-hover:hover:text-white has-hover:hover:bg-cornflower-blue active:text-white active:bg-cornflower-blue",
    )
    .with(
      { alternative: "primary", isLoading: true },
      () => "text-white bg-cornflower-blue",
    )
    .with({ alternative: "secondary" }, () => "bg-white")
    .with(
      { alternative: "tertiary" },
      () => "text-black bg-pastel-green-2 hover:bg-white",
    )
    .with({ alternative: "quaternary" }, () => "text-white bg-pink")
    .with({ alternative: "enabled" }, () => "text-white bg-cornflower-blue")
    .with({ alternative: "transparent" }, () => "text-white border-white")
    .with({ alternative: "white" }, () => "text-black  bg-white border-black")
    .with({ alternative: "blackfriday" }, () => "text-white bg-christmas")
    .exhaustive();

export type Alternative =
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "enabled"
  | "transparent"
  | "white"
  | "blackfriday";

export enum Shadow {
  Black,
  White,
  None,
}

type ButtonExtraProps = {
  alternative?: Alternative;
  isLoading?: boolean;
  shadow?: Shadow;
  borderOnMobile?: boolean;
};

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  ButtonExtraProps;

export const Button = ({
  children,
  className,
  alternative = "primary",
  disabled = false,
  isLoading = false,
  shadow,
  borderOnMobile,
  ...props
}: ButtonProps) => {
  const isInputDisabled = !!disabled;
  const isDisabled = isInputDisabled || isLoading;

  return (
    <>
      {isInputDisabled ? (
        <DisabledButton className={className} disabled={disabled} {...props}>
          {children}
        </DisabledButton>
      ) : (
        <PushableButton
          isDisabled={isDisabled}
          className={cx(
            "text-lg font-medium",
            getAlternativeColors(alternative, isLoading),
            className,
          )}
          shadow={shadow}
          borderOnMobile={borderOnMobile}
          {...props}
        >
          {isLoading && <ButtonLoader alternative={alternative} />}
          <div className={cx({ invisible: isLoading })}>{children}</div>
        </PushableButton>
      )}
    </>
  );
};
